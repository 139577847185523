import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxHttpLoaderModule } from 'ngx-http-loader';
import { CoreModule } from './core/core.module';
import { MessageModule } from './kernel/tools/message/message.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './vogo-core/interceptors/JwtInterceptor';
import {DashboardModule} from "src/app/applications/dashboard/dashboard.module";
import {AuthenticationModule} from "src/app/applications/authentication/authentication.module";
import {PagesModule} from "src/app/applications/pages/pages.module";

import { loadLocale } from './core/locale';
import { LocalStorageService } from './kernel/tools/services/local-storage.service';
import { NotificationModule } from './kernel/notifications/notification.module';
import { DashboardLayoutModule } from './layout/dashboard-layout/dashboard-layout.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthService } from './kernel/tools/services/auth.service';
import { AuthService as VogoAuthService } from './vogo-core/services/auth.service'


// ===================== SUB APPLICATIONS { =====================

// ===================== } SUB APPLICATIONS =====================

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxHttpLoaderModule.forRoot(),
    CoreModule,
    MessageModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    DashboardModule,
    DashboardLayoutModule,
    NotificationModule.forRoot(),
    PagesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID, 
      useFactory: loadLocale,
      deps: [LocalStorageService]
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'AED',
    },
    { provide: AuthService, useExisting: VogoAuthService },
    // // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
